
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import Table from "@/components/Table.vue";
import moment from "moment";
import { useRouter, useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "admin-organisations",
  components: {
    Table,
    Form,
    Field,
  },
  async mounted() {
    this.init();
  },
  data() {
    console.log();
    const store = useStore();
    const route = useRoute();
    // const pckg = route.params.pckg ?? null;
    const url = process.env.VUE_APP_API_URL + "/organisations/list"; // ✅
    const filter = {
      byStatus: "",
      byType: "",
      byBranch: "",
    };

    const statuses = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "1",
      },
      {
        label: "Inactive",
        value: "0",
      },
    ];

    const types = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Branch",
        value: "branch",
      },
      {
        label: "Advisory Office",
        value: "fa_office",
      },
    ];

    const branches = [];

    const { n, t } = useI18n();

    const selectedMonth = 1;
    const selectedCloseMonth = 1;
    const months = [
      { value: 1, label: "January" },
      { value: 2, label: "February" },
      { value: 3, label: "March" },
      { value: 4, label: "April" },
      { value: 5, label: "May" },
      { value: 6, label: "June" },
      { value: 7, label: "July" },
      { value: 8, label: "August" },
      { value: 9, label: "September" },
      { value: 10, label: "October" },
      { value: 11, label: "November" },
      { value: 12, label: "December" },
    ];
    const selectedYear = 2023;
    const selectedCloseYear = 2023;
    const years = [
      { value: 2023, label: "2023" },
      { value: 2024, label: "2024" },
      { value: 2025, label: "2025" },
    ];

    const calendar = {} as any;
    const closeCalendar = {} as any;

    return {
      // pckg,
      store,
      filter,
      statuses,
      types,
      branches,
      selectedMonth,
      selectedCloseMonth,
      months,
      selectedYear,
      selectedCloseYear,
      years,
      calendar,
      closeCalendar,
      ajax: url,
      order: [[0, "asc"]],
      columns: [
        {
          data: "id",
          title: "No",
          defaultContent: "-",
          className: "text-center",
          render: function (data, type, row, meta) {
            return meta.row + meta.settings._iDisplayStart + 1;
          },
        },
        {
          data: "name",
          defaultContent: "-",
          title: "Name",
        },
        {
          data: "type",
          title: "Type",
        },
        {
          data: "branch",
          title: "Branch",
        },
        {
          data: "address",
          defaultContent: "-",
          title: "Address",
        },
        {
          data: "status",
          title: "Status",
        },
        {
          data: "action",
          title: "Action",
        },
      ],
    };
  },
  setup(props) {
    const { t } = useI18n();

    return { t };
  },
  methods: {
    init() {
      this.calendar = this.getDaysArray(2023, 1);
      this.closeCalendar = this.getDaysArray(2023, 1);

      setCurrentPageBreadcrumbs("Organisations", ["Administration"]);

      this.store.dispatch(Actions.GET_BRANCHES).then(() => {
        this.branches = this.store.getters.getBranchesData;
      });
    },
    dtFilterOrganisations() {
      const table = this.$refs.tableOrganisations as any;
      let urlAjax = this.ajax;
      const queryParams = [];

      if (this.filter.byStatus) {
        queryParams.push(`filterByStatus=${this.filter.byStatus}`);
      }

      if (this.filter.byType) {
        queryParams.push(`filterByType=${this.filter.byType}`);
        if (this.filter.byType == "branch") {
          this.filter.byBranch = ""
        }
      }else{
        this.filter.byBranch = ""
      }

      if (this.filter.byBranch) {
        queryParams.push(`filterByBranch=${this.filter.byBranch}`);
      }

      if (queryParams.length > 0) {
        urlAjax += "?" + queryParams.join("&");
      }

      table?.dataTable.ajax.url(urlAjax).load().draw();
    },
    changeCalendar(type) {
      if (type == "set") {
        this.calendar = this.getDaysArray(
          this.selectedYear,
          this.selectedMonth
        );
      } else {
        this.closeCalendar = this.getDaysArray(
          this.selectedCloseYear,
          this.selectedCloseMonth
        );
        console.log(this.closeCalendar);
      }
    },
    getDaysArray(year, month) {
      console.log(year);
      var monthIndex = month - 1; // 0..11 instead of 1..12
      var names = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      var date = new Date(year, monthIndex, 1);
      var result = [];
      while (date.getMonth() == monthIndex) {
        let slots = {
          isAvalaible: true,
          date: date.getDate(),
          day: names[date.getDay()],
        };
        result.push(slots);
        date.setDate(date.getDate() + 1);
      }
      return result;
    },
    onSubmitSetDate() {
      let disabled_date = [];

      for (let cal of Object.keys(this.calendar)) {
        if (!this.calendar[cal].isAvalaible) {
          disabled_date.push(this.calendar[cal].date);
        }
      }

      const payload = {
        month: this.selectedMonth,
        year: this.selectedYear,
        disabled_date: disabled_date,
      };

      this.store.dispatch(Actions.STORE_APP_DATES, payload).then(() => {
        let response = this.store.getters.getAppDatesResponse;
        console.log(response.result);

        if (response.result == "Success") {
          Swal.fire({
            title: "Success",
            text:
              "Appointment Dates have been successfully added for all Branches ",
            icon: "success",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: "Error",
            text:
              "Appointment Date have already been set for the selected month and year. If you want to disabled some dates, please go to the Close Appointment Dates section below or go to Edit Organisation Page.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      });
    },
    onSubmitCloseDate() {
      let disabled_date = [];

      for (let cal of Object.keys(this.closeCalendar)) {
        if (!this.closeCalendar[cal].isAvalaible) {
          disabled_date.push(this.closeCalendar[cal].date);
        }
      }

      const payload = {
        month: this.selectedCloseMonth,
        year: this.selectedCloseYear,
        disabled_date: disabled_date,
      };

      this.store.dispatch(Actions.UPDATE_APP_DATES, payload).then(() => {
        let response = this.store.getters.getCloseAppDatesResponse;
        console.log(response.result);

        if (response.result == "Success") {
          Swal.fire({
            title: "Success",
            text:
              "Appointment Dates have been successfully upodated for all Branches",
            icon: "success",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: "Error",
            text:
              "Appointment Date have not been set for the selected month and year. If you want to Set Appointment Dates, please go to the Set Appointment Dates section above.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      });
    },
  },
});
